<template>
  <div style="background-color: #fff">
    <a-table rowKey="id" size="small" :columns="columns" :dataSource="items" :pagination="false" />
  </div>
</template>

<script>
export default {
  props: ["items"],
  data() {
    return {
      // Table
      columns: [
        {
          title: "序号",
          dataIndex: "index",
          width: 60,
          customRender: (_, __, index) => index + 1,
        },
        {
          title: "子产品名称",
          dataIndex: "goods_name",
          customRender: (_, item) => item.goods_item.name,
        },
        {
          title: "子产品编号",
          dataIndex: "goods_number",
          customRender: (_, item) => item.goods_item.number,
        },
        {
          title: "产品分类",
          dataIndex: "category_name",
          customRender: (_, item) => item.goods_item.category_name,
        },
        {
          title: "规格",
          dataIndex: "spec",
          customRender: (_, item) => item.goods_item.spec,
        },
        {
          title: "图号",
          dataIndex: "drawing_number",
          customRender: (_, item) => item.goods_item.drawing_number,
        },
        {
          title: "来源",
          dataIndex: "source",
          customRender: (_, item) => item.goods_item.source.join(", "),
        },
        {
          title: "数量",
          dataIndex: "quantity",
        },
      ],
    };
  },
};
</script>

<style scoped></style>
